<template>
  <div class="informationbase">
    <div :class='searchCondData == "" || searchCondData.length == 0 || searchCondData == undefined || searchCondData == null? "informationbase-headtop-wrap" : "informationbase-headtop-wrap1" '>
      <div class="informationbase-headtop-wrap-fix">
      <navigation-bar @search="handleSearch">
        <!-- <template #nleft> 12123 </template> -->
        <template v-slot:nright>
          <div class="bars" @click="slideMenu"><img src="~@image/bars.png" alt=""/></div>
        </template>
      </navigation-bar>

      <!--screening-condition-->
      <div class="search-condition-wrap">
        <search-condition
          v-for="(data, index) in searchCondData"
          :data="data"
          :index="index"
          :key="index"
          @delThisCond="delThisCond"
          @onThisCond="onThisCond"
        />
      </div>

      <!-- <search-condition /> -->
      <ul class="actionbar">
        <li class="actionbar-item" @click="handleAllChecked">
          <div class="actionbar-item-icon">
            <img src="~@image/select-n.png" alt="" v-if="!isAllcheck" />
            <img src="~@image/select-h.png" alt="" v-else />
          </div>
          全选
        </li>
        <li class="actionbar-item" @click="handleAddComp">
          <div class="actionbar-item-icon">
            <img src="~@image/addhuibian.png" alt="" />
          </div>
          添加汇编
        </li>

        <li class="actionbar-item" @click="handleMergeWord" >
          <div class="actionbar-item-icon">
            <img src="~@image/hbcc.png" alt="" />
          </div>
          汇编成册
          <div class="badge">
             <span>{{UserDocTempCount}}</span>
          </div>
          <!-- <van-badge :content="UserDocTempCount">
            <div class="child" />
          </van-badge> -->
        </li>

        <li class="actionbar-item" @click="BatchDownload">
          <div class="actionbar-item-icon">
            <img src="~@image/infoDo.png" alt="" />
          </div>
          下载
        </li>
        
        <li class="actionbar-item" @click="handleSort">
          <div class="actionbar-item-icon" :class="this.eventdate == 'asc' ? 'handstand':''">
            <img src="~@image/timesort.png" alt="" />
          </div>
          时间
        </li>
      </ul>
      </div>
    </div>

    <infomation-menu :show="showInfoMenu" @menuSlideInfo="menuSlideInfo"/>
    <div class="over" v-if="showInfoMenu" @click="menuSlideInfo"></div>

    <van-list
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      :offset="100"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <ul class="resultitem">
        <result-item
          v-for="(item,index) in showData"
          :data="item"
          :key="index"
          @handleCheck="handleCheck"
          @handleDownload="handleSingleDownload"
        />
        <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
      </ul>
    </van-list>
    <!-- <reslut-item :data="showData" /> -->
    <!-- <go-top /> -->

    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="block-header">
            <div class="block-header-title">汇编成册</div>
          </div>
          <div class="block-content">自动生成含目录的资料汇编。</div>
          <div class="block-footer">
            <div class="block-footer-btn" @click="show = false">取消</div>
            <div class="block-footer-btn" @click="handleClearMerge">
              清空汇编
            </div>
             <div class="block-footer-btn sure" @click="handleConfirm">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="showmerge">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="block-header">
            <div class="block-header-title">汇编成册</div>
          </div>
          <div class="block-content">
            {{infoBatchlength == 0 ?`您已下载过此文档，本次下载将不扣除下载券，余额下载券为${downloadCount}卷。`:`本次将扣减您${infoBatchlength}下载卷，余额下载券为${downloadCount}卷。`}}
          </div>
          <div class="block-footer">
            <div class="block-footer-btn" @click="showmerge = false">取消</div>
            <div class="block-footer-btn sure" @click="handleMergeConfirm">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="showSingleDownload">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="block-header">
            <div class="block-header-title">下载</div>
          </div>
          <div class="block-content">
            本次将扣减您1下载卷，余额下载券为{{ downloadCount }}卷。
          </div>
          <div class="block-footer">
            <div class="block-footer-btn" @click="showSingleDownload = false">
              取消
            </div>
             <div class="block-footer-btn sure" @click="handleSingleConfirm">
              确定
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="showTicketIsNotEnough">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="block-header">
            <div class="block-header-title">下载</div>
          </div>
          <div class="block-content">
            本次将扣减您{{UserDocTempCount}}下载卷，余额不足请充值。
          </div>
          <div class="block-footer">
            <div class="block-footer-btn" @click="showTicketIsNotEnough = false">
              取消
            </div>
            <div class="block-footer-btn sure" @click="toRecharge">
              去充值
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="infoBatchDownload">
      <div class="wrapper" @click.stop>
        <div class="block">
           <div class="block-header">
            <div class="block-header-title">下载</div>
          </div>
          <div class="block-content">
            本次将扣减您{{infoBatchlength}}下载卷，余额下载券为{{ downloadCount }}卷。
          </div>
          <div class="block-footer">
            <div class="block-footer-btn" @click="infoBatchDownload = false">
              取消
            </div>
             <div class="block-footer-btn sure" @click="infoBatchDownloadFix">
              确定
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

  </div>
</template>

<script>
// 导航栏组件
import NavigationBar from "@components/common/infoNavBar.vue";
// 搜索组件
// import SearchCondition from "./common/SearchCondition.vue";
// 结果列表
// import GoTop from "@components/common/GoTop";
import ResultItem from "./home/ResultItem.vue";
import {
  getHomeData,
  getUserDocTempCount,
  addDocTempInfo,
  delDocTempInfo,
  getUserCountDownlod,
  infoBatchDownload,
} from "@api/information.js";
import qs from "qs";
import SearchCondition from "@components/common/SearchCondition.vue";
import InfomationMenu from "@components/common/InfomationMenu.vue";
import EventBus from "@/event-bus/eventbus.js";
// import {checkDownload} from "../api/information";
export default {
  name: "WInformationbase",
  data() {
    return {
      count: "",
      // 权威性
      authority: "",
      // 选中类型
      doctypeCheck: "",
      // 时间排序
      eventdate: "desc",
      // 热度
      hot: "",
      // 默认查找
      infoBase: 1,
      // 搜索关键字
      key: "",
      // 查询的关键字id
      meunIds: 13,
      multiple: "",
      // 页码
      pageNo: 0,
      // 第一次请求后返回
      page_uuid: " ",
      pubCheck: "",
      // 展示的数据
      showData: [],
      // 列表的加载
      loading: false,
      // 是否加载完全部
      finished: false,
      // 显示汇编成册的数量
      UserDocTempCount: "",
      // 存储汇编的docId
      docId: [],
      //  控制汇编提示的显示隐藏
      show: false,
      // 下载券数量
      downloadCount: "",
      // 控制确认汇编
      showmerge: false,
      // 单个下载
      showSingleDownload: false,
      // 单个下载的iD
      singleDownloadId: "",
      // 单个下载的文件名
      singleDownloadFilename: "",
      // 是否是全选状态
      Allcheck: false,
      // 搜索条件数据
      searchCondData: [],
      // 搜索总数
      totalNum: 0,
      // 菜单侧栏显示隐藏
      showInfoMenu : false,
      // 下载券不足
      showTicketIsNotEnough: false,
      //批量下载
      infoBatchDownload:false,
      infoBatchlength:"",
      infoBatch:[],
      tempIds:''
    };
  },
  components: {
    NavigationBar,
    // SearchCondition,
    ResultItem,
    // NavigationBar,
    // GoTop,
    SearchCondition,
    InfomationMenu,
  },
  created() {
    // 获取汇编成册的数量
    this.getDocTempCount();

    // 获取首次加载的数据
    // this.getData();
  },
 
  mounted() {
   
    //点击导航菜单触发
    EventBus.$on("toInfoSearch",data => {
      let isDupSch;
      // let topId = this.overParentTree(data.parentId);
      // if(topId){
      //   this.searchCondData.forEach((item,index) => {
      //     if(item.id == topId){
      //       item = data;
      //       this.onThisCond(index);
      //     }
      //   });
      // }
      this.searchCondData.forEach((item,index) => {
        //同一顶级目录下标签相互替换
        if(data.parentId === item.id || data.id === item.parentId){
          this.searchCondData.splice(index,1)
        }
        if(item.id === data.id){
          item.isCheck = true;
          isDupSch = true;
        }else{
          item.isCheck = false;
        }
      });
      if(isDupSch) return;
      this.showData = [];
      this.meunIds = data.id;
      this.page_uuid = " ";
      
      this.getData().then(result => {
        if(result){
          var items = {
            id : data.id,
            key : data.name,
            total : result.data.totalNum,
            parentId : data.parentId,
            isCheck: true,
          };
          this.searchCondData.push(items)
        }else{
          setTimeout(()=>{
            this.$toast("没有相关结果");
          },500);
        }
      });
    });
    
  },
  computed: {
    // 计算 是否是全选状态
    isAllcheck() {
      return this.showData.every((item) => item.isCheck);
    },
    //iPhone
    isiPhone(){
      return /iPhone|iPad/i.test(navigator.userAgent);
    }
  },
  watch:{
     showInfoMenu:{
       handler:function(cur,old){
           if(cur == true){
                this.stop()
                if(window.android){
                   const info = {
                        action:"menuVisible",
                        data:{
                          type:1
                        }
                   }
                   window.android.postMessage(JSON.stringify(info))
                }else if(window.webkit){
                    const info = {
                        action:"menuVisible",
                        data:{
                          type:1
                        }
                    }
                    window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(info));
                }
           }else{
                this.move()
                if(window.android){
                   const info = {
                        action:"menuVisible",
                        data:{
                          type:0
                        }
                   }
                   window.android.postMessage(JSON.stringify(info))
                }else if(window.webkit){
                    const info = {
                        action:"menuVisible",
                        data:{
                          type:0
                        }
                    }
                    window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(info));
                }
           }
       },
       deep:true,
     }
  },
  methods: {
    // overParentTree(id){
    //   let istop;
    //   let topId;
    //   let parentTree;
    //   this.searchCondData.forEach(item => {
    //     if(item.id == id && item.parentId == 1){
    //       istop = true;
    //       topId = item.id;
    //     }else if(item.id == id){
    //       parentTree = item;
    //       istop = false;
    //     }else{
    //       istop = false;
    //     }
    //   });
    //   if(istop) return topId;
    //   if(parentTree){
    //     return this.overParentTree(parentTree.parentId);
    //   }
    // },
    //滑出菜单
    slideMenu(){
      this.showInfoMenu = true;
    },
    //返回信息库首页
    menuSlideInfo(){
      this.showInfoMenu = false;
    },
     //禁止滚动
   stop(){
        document.body.style.position = 'fixed';
        document.body.style.overflow = 'hidden';
    },
    // 取消滑动限制
    move(){
       document.body.style = '';
    },
    //批量下载
    BatchDownload(){
      let docId = [];
      // 条件筛选 提取已选中的
      this.showData.filter((item) => {
        // if   item.isCheck === true
        if (item.isCheck == true) {
          docId.push(item.id);
        }
      });
      if(docId.length==0){
         this.$toast("请选择文档！")
      }else{
          this.getUserCountDownlod()
          this.infoBatchDownload = true
          this.infoBatch = docId
        // }
      }
    },
    //批量下载确定
    infoBatchDownloadFix(){
        let ids = this.infoBatch.join(",")
        if(window.android){
          const info = {
            action:'writerMergeWord',
            data:{
              type:"3",
              ids:ids
            }
          }
          window.android.postMessage(JSON.stringify(info))
        }else if(window.webkit){
           const info = {
            action:'writerMergeWord',
            data:{
              type:"3",
              ids:ids
            }
           }
           window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(info));
        }else{
          this.infoBatchDownload = false
        }
        this.infoBatchDownload = false
    },
    // 确定单个下载
    handleSingleConfirm() {
      if (window.android) {
        const params = {
          action: "writerMergeWord",
          data: {
            type: 1,
            ids: this.singleDownloadId,
            filename: this.singleDownloadFilename,
          },
        };

        window.android.postMessage(JSON.stringify(params));
      } else if (window.webkit) {
        // ios 环境下
        const params = {
          action: "writerMergeWord",
          data: {
            type: 1,
            ids: this.singleDownloadId,
            filename: this.singleDownloadFilename,
          },
        };
        let jsonStr = JSON.stringify(params);
        window.webkit.messageHandlers.sendMessageForVue.postMessage(jsonStr);
      } else {
        // 隐藏弹出框
        this.showSingleDownload = false;
      }
      // 隐藏弹出框
      this.showSingleDownload = false;
    },
    // 打开单个下载的弹窗
    handleSingleDownload(payload) {
      // 打开弹出框
      this.showSingleDownload = true;
      // 获取下载券的数量
      this.getUserCountDownlod();
      this.singleDownloadId = payload.id;
      this.singleDownloadFilename = payload.title;
    },
    // 打开汇编成册窗口
    handleMergeWord() {
      this.show = true;
    },

    // 确认汇编成册
    handleMergeConfirm() {
      if (window.android) {
        const params = {
          action: "writerMergeWord",
          data: {
            type: 2,
          },
        };
        window.android.postMessage(JSON.stringify(params));
      } else if (window.webkit) {
        const params = {
          action: "writerMergeWord",
          data: {
            type: 2,
          },
        };
        let jsonStr = JSON.stringify(params);
        window.webkit.messageHandlers.sendMessageForVue.postMessage(jsonStr);
      }

      setTimeout(() => {
        this.showmerge = false;
      }, 2000);
    },

    // 获取下载券数量
    async getUserCountDownlod(tempIds) {
      let docId = []
      this.showData.filter((item) => {
        // if   item.isCheck === true
        if (item.isCheck == true) {
          docId.push(item.id);
        }
      });
      let ids = ''
      for (let id of docId) {
        ids = ids + id+ ','
      }

      if (tempIds){
        ids = tempIds
      }
      const result = await getUserCountDownlod(qs.stringify({ type: 3 , ids : ids}));
      this.infoBatchlength = result.data.useNum
      this.downloadCount = result.data.availNum
      // this.UserDocTempCount = result.data.useNum
    },

    //去充值
    toRecharge(){
      this.$router.push('/home/ServiceMall/ServiceMalladmin')
    },
    //  确定汇编成册
    handleConfirm() {
      // 判断是否有汇编
      if (this.UserDocTempCount == 0) {
        this.$toast({
          message: "请选择一个文档进行添加",
          position: "top",
        });
        return;
      }
      Promise.all([this.getUserCountDownlod(),this.getDocTempCount()])
          .then(()=>{
            if (this.downloadCount < this.UserDocTempCount){
              this.show = false
              this.showTicketIsNotEnough = true
            }else {
              // 隐藏之前的弹窗
              this.show = false;
              // 获取下载券
              this.getUserCountDownlod(this.tempIds);
              // 展示提示下载券弹窗
              this.showmerge = true;
            }
          })

    },
    // 清空汇编成册
    async handleClearMerge() {
      const result = await delDocTempInfo();
      // 成功清空后才更新汇编的数量
      if (result.code === 0) {
        this.getDocTempCount();
        this.show = false;
      }
    },
    // 获取汇编成册的数量
    async getDocTempCount() {
      const result = await getUserDocTempCount();
      this.UserDocTempCount = result.data;
      this.tempIds = result.msg
    },
    // 添加汇编成册
    async addDocTemp() {
      let docId = [];
      // 条件筛选 提取已选中的
      this.showData.filter((item) => {
        // if   item.isCheck === true
        if (item.isCheck) {
          docId.push(item.id);
        }
      });
      // 将数组转换 字符串
      docId = docId.join(",");

      if (!docId) {
        this.$toast({
          message: "请选择一个文档进行添加",
          position: "top",
        });
        return;
      }
      const result = await addDocTempInfo({ docId });
      if (result.code === 0) {
        this.$toast({
          message: `最大汇编100篇，还可添加${result.data}篇，点击汇编成册自动生成资料汇编！`,
        });

        this.getDocTempCount();
      } else {
        this.$toast({
          message: result.msg,
        });
      }
    },
    onLoad() {
        this.pageNo += 1;
        this.getData();
    },
    // 监听触发的搜索事件
    handleSearch(val) {
      if( val !== "" && this.key == val){
        this.$toast("重复关键字!");
        return;
      }
      this.showData = [];
      this.page_uuid = " ";
      if(val == ""  || val == undefined ){
        this.key = ""
        this.getData()
      }else{
        this.key = val;
        this.getData().then(()=>{
        if (this.showData.length < 1) {
          this.$toast("没有相关结果");
          return;
        }
      });
      }
    },
    // 触发全选方法
    handleAllChecked() {
      if (!this.Allcheck) {
        // 全选
        this.showData = this.showData.map((item) => {
          return {
            ...item,
            isCheck: true,
            // isCheckShuo: true,
          };
        });
        this.Allcheck = true;
      } else {
        // 反选
        this.showData = this.showData.map((item) => {
          return {
            ...item,
            isCheck: false,
            // isCheckShuo: true,
          };
        });
        this.Allcheck = false;
      }
    },
    // 添加汇编方法
    handleAddComp() {
      this.addDocTemp();
    },
    // 排序 时间排序
    handleSort() {
      this.showData = [];
      this.page_uuid = " ";
      if (this.eventdate === "asc") {
        this.eventdate = "desc";
      } else {
        this.eventdate = "asc";
      }
      this.getData();
    },
    // 首页数据的请求
    async getData() {
      this.$toast.loading({
        forbidClick: true,
        loadingType: "spinner",
      });
      const requestData = {
        infoBase: this.infoBase,
        pageNo: this.pageNo,
        page_uuid: this.page_uuid,
        authority: this.authority,
        doctypeCheck: this.doctypeCheck,
        eventdate: this.eventdate,
        hot: this.hot,
        key: this.key,
        meunIds: this.meunIds,
        multiple: this.multiple,
        pubCheck: this.pubCheck,
      };
      const result = await getHomeData(qs.stringify(requestData));
      if (result.code === 0) {
        if(result.data.data.length == 0) {
           this.loading = false;
           this.finished = true;
        }else {
            this.$toast.clear();
            this.page_uuid = result.data.page_uuid;
            let data = result.data.data;
            const fillterData = this.addDatePropties(data);
            this.showData.push(...fillterData);
            this.pageCount = data.totalPages;
            this.totalNum = result.data.totalNum;
            this.finished = false;
            this.loading = false;
            return result;
        }
      } else {
        this.$toast(result.data.msg);
      }
    },
    // 过滤数据
    addDatePropties(arr) {
      return arr.map((item) => {
        return {
          ...item,
          isCheck: false,
          // isCheckShuo: false,
        };
      });
    },
    // 选中过滤
    addfilterChecked(arr, id) {
      return arr.map((item) => {
        if (item.id === id) {
          item.isCheck = item.isCheck === false ? true : false;
          return {
            ...item,
          };
        } else {
          return {
            ...item,
          };
        }
      });
    },
    // 选中标准版的事件派发
    handleCheck(id) {
      this.showData = this.addfilterChecked(this.showData, id);
      this.Allcheck = false;
    },
    //删除筛选条件
    delThisCond(i) {
      this.searchCondData.splice(i, 1);
      if (this.searchCondData.length == 0) {
        this.showData = [];
        this.page_uuid = " ";
        this.meunIds = 13;
        this.getData();
        return;
      }
      let last = this.searchCondData.length - 1;
      this.searchCondData.forEach((item, index) =>
        last == index ? (item.isCheck = true) : (item.isCheck = false)
      );
      this.showData = [];
      this.meunIds = this.searchCondData[last].id;
      this.page_uuid = " ";
      this.getData();
      this.$router.go(0)
    },
    //选择一个筛选条件
    onThisCond(i) {
      this.searchCondData.forEach((item, index) => {
        if (index == i) {
          item.isCheck = true;
          this.showData = [];
          this.page_uuid = " ";
          this.meunIds = item.id;
          this.getData();
        } else {
          item.isCheck = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.informationbase {
  height: 100%;
  padding-bottom: 50px;
  font-family: PingFang SC;
  &-headtop-wrap{
    width:100%;
    height:80px;
    &-fix{
      position:fixed;
      top:0;
      z-index:100;
      background-color:#fff;
      padding-bottom:20px;
    }
  }
  &-headtop-wrap1{
    height:106px;
  }
  &-headtop-wrap2{
    height:45px;
  }
  ::v-deep .van-overlay{
    z-index: 999;
  }
  .bars{
    display:flex;
    align-items:center;
    margin-left:15px;
    img{
      width: 22px;
      height: 16px;
    }
  }
  .over{
    position:fixed;
    top:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,.5);
    z-index: 100;
  }
  .search-condition-wrap {
    display: flex;
    align-items: center;
    width: 360px;
    margin: 10px 0 0 15px;
    overflow-x: scroll;
  }
  .actionbar {
    display: flex;
    align-items: center;
    padding: 0 15px;
    height: 15px;
    margin-top: 20px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    &-item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      position: relative;
      & + li {
        margin-left: 13px;
      }
      &:last-child {
        margin-left: auto;
      }
      &-icon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
        img {
          width: 100%;
          height: inherit;
        }
      }
      .handstand{
        transform: rotate(180deg);
      }
      .badge {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #fff;
        background-color: #f01414;
        width: 24px;
        height: 15px;
        line-height: 15px;
        text-align: center;
        span{
          width: 100%;
          height: 100%;
          text-align: center;
          line-height: 12.5px;
        }
      }
    }
  }
  .resultitem {
    margin-top: 15px;
    padding: 0 15px;
    font-family: PingFang SC;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 285px;
      border-radius: 5px;
      height: 169px;
      padding: 0 21px;
      background-color: #fff;
      position: relative;
      &-header {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 15px;
        &-title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
        }
      }
      &-content {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-top: 20px;
      }
      &-footer {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        &-btn{
          flex: 1;
          text-align: center;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
        }
        .sure{
          color: #4588FF;
        }
      }
      
    }
  }
}

</style>
