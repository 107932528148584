<template>

  <div :class="['infoTree-toplv',{'infoTree-file' : isfolder}]">

    <div class="infoTree-item" @click="slideDrop" :data-id="topid">

      <div class="con">

        <img src="~@image/informationBase-right.png" v-if="model.isshow"/>

        <span class="txt" :class="isDrop?'active':''">{{model.name}}</span>

      </div>

      <span class="drop" v-if="isfolder">

        <van-icon name="arrow" size="12" v-show="!isDrop"/>

        <van-icon name="arrow-down" size="12" v-show="isDrop"/>

      </span>

    </div> 

    <div class="infoTree-droplv" v-show="isDrop" v-if="isfolder">

      <info-tree v-for="(item,index) in model.children" :model="item" :child="children" :topid="topid" :key="index"/>

    </div>

  </div>

</template>

<script>
import EventBus from "@/event-bus/eventbus.js";
export default {
  name : "InfoTree",
  props : {
    model : Object,
    topid : Number,
    child : String
  },
  inheritAttrs : false,
  data(){
    return {
      //展示下级菜单
      isDrop : false,
      children : '0'
    }
  },
  methods : {
    slideDrop(){
      this.isDrop = !this.isDrop;
      console.log(this.child)
      /*if(!this.isfolder || this.isDrop){

      }*/
      let data = {
        name : this.model.name,
        id : this.model.id,
        parentId : this.model.parentId,
      }
      EventBus.$emit("toInfoSearch",data);
    },
  },
  computed : {
    //是否有下级
    isfolder(){
      return this.model.children && 
      this.model.children.length;
    }
  },
  created(){  
  }
}
</script>

<style lang="scss" scoped>
.infoTree-toplv{
  position:relative;
  padding:10px 10px 0 15px;
  .infoTree-item{
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    height:54px;
    border-bottom:1px solid #eee;
    .con{
      // width:150px;
      // white-space:nowrap;
      // text-overflow:ellipsis;
      // overflow:hidden;
      img{
        width:17px;
        height:17px;
        margin-bottom:2px;
        vertical-align:middle;
      }
      span{
        margin-left:10px;
        display: inline-block;
      }
      .active{
        color: #4587ff;
      }
    }
  }
  .infoTree-droplv{
    position:relative;
    top:0;
    width:100%;
    .infoTree-item{
      height:34px;
      border-bottom:none;
    }
  }
}
</style>