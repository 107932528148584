<template>
  <div class="SearchCondition" @click="onThisCond">
    <div
      :class="[
        'SearchCondition-item',
        data.isCheck ? 'SearchCondition-item-h' : '',
      ]"
    >
      <div class="SearchCondition-item-title">{{ data.key }}</div>
      <div class="SearchCondition-item-count">{{ data.total }}</div>
      <div class="SearchCondition-item-close">
        <van-icon
          name="cross"
          size="12px"
          :color="data.isCheck ? '#fff' : '#3783F3'"
          @click.stop="delThisCond"
          class="iconClose"
        />
      </div>
    </div>
    <!-- <li class="SearchCondition-list-item"></li>
      <li class="SearchCondition-list-item"></li>
      <li class="SearchCondition-list-item"></li>
      <li class="SearchCondition-list-item"></li> -->
  </div>
</template>

<script>
export default {
  name: "SearchCondition",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  methods: {
    onThisCond() {
      this.$emit("onThisCond", this.index);
    },
    delThisCond() {
      this.$emit("delThisCond", this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.SearchCondition {
  flex-shrink: 0;
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 26px;
    padding: 0 9px;
    font-family: PingFang SC;
    margin-right: 20px;
    color: #3783f3;
    font-size: 10px;
    background: #ffffff;
    border: 1px solid #3783f3;
    border-radius: 13px;
    &-title {
      margin-right: 10px;
    }
    &-count {
      margin-right: 10px;
      margin-top: 1px;
    }
  }
  &-item-h {
    color: #fff;
    background-color: #3783f3;
  }
}
::v-deep .van-icon{
  margin-top: 4px;
}
</style>
