<template>
  
  <transition name="slide">

    <div class="InfomationMenu" v-show="show">

      <van-nav-bar 
        title="菜单总栏" 
        left-text="返回" 
        left-arrow 
        @click-left="onClickLeft"
      />

      <div class="main">

        <info-tree v-for="(item,index) in infoTree" :model="item" :topid="item.id" :key="index" />

      </div>

    </div>

  </transition>

</template>

<script>
import { findMenuTree } from "@api/information.js";
import InfoTree from "@components/common/InfoTree.vue";
export default {
  props : ["show"],
  name : "InfomationMenu",
  data(){
    return {
      // 信息树
      infoTree : []
    }
  },
  methods : {
    onClickLeft(){
      this.$emit("menuSlideInfo");
    }
  },
  components : {
    InfoTree
  },
  created(){
    findMenuTree().then(res=>{
      if(res.code == 0){
        this.infoTree = res.data[0].children;
        this.infoTree.map(item =>{
          item.isshow = true
        })
      }else{
        this.$toast(res.msg)
      }
    });
  }
}
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar .van-icon-arrow-left{
  color: #333;
}
::v-deep .van-nav-bar .van-nav-bar__text{
  color:#333;
}
::v-deep .van-nav-bar__title {
  font-size:17px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333
}
.slide-enter{
  transform:translateX(100%);
}
.slide-enter-active{
  transition:all .3s;
}
.slide-enter-to{
  transform:translateX(0);
}
.slide-leave{
  transform:translateX(0);
}
.slide-leave-active{
  transition:all .3s;
}
.slide-leave-to{
  transform:translateX(100%);
}
.InfomationMenu{
  position:fixed;
  z-index:101;
  top:0; 
  right:0;
  width:325px;
  height:100%;
  background-color:#fff;
  .main{
    width:100%;
    max-height:621px;
    border-top:10px solid #F4F4F4;
    overflow-y:scroll;
  }
}

</style>