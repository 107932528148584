import request from "@/utils/request";
// es信息库搜索
export function getHomeData(data) {
  return request({
    url: "esSearch/loadElastPageData",
    method: "post",
    data,
  });
}
// 获取汇编的数量
export function getUserDocTempCount(data) {
  return request({
    url: "system/userDocTempCount",
    method: "post",
    data,
  });
}

// 收藏
export function getUserCollection(data) {
  return request({
    url: "esSearch/addInfoCollect",
    method: "post",
    data,
  });
}

//取消收藏
export function batchCollect(data) {
  return request({
    url: "esSearch/delInfoCollect",
    method: "post",
    data,
  });
}


//批量下载
export function infoBatchDownload(data) {
  return request({
    url: "esSearch/infoBatchDownload",
    method: "post",
    data,
  });
}

// 添加汇编/system/addDocTempInfo

export function addDocTempInfo(data) {
  return request({
    url: "system/addDocTempInfo",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

// 清空汇编
export function delDocTempInfo(data) {
  return request({
    url: "system/delDocTempInfo",
    method: "post",
    data,
  });
}

// /system/writerMergeWord 汇编成册

export function writerMergeWord(data) {
  return request({
    url: "system/writerMergeWord",
    method: "post",
    data,
  });
}

// 获取用户的下载卷数量
export function getUserCountDownlod(data) {
  return request({
    url: "neword/getUserCount",
    method: "post",
    data,
  });
}

// 打开文档详情

export function openWordDetail(data) {
  return request({
    url: "system/openWord",
    method: "post",
    data,
  });
}

// 信息库首页保存到云盘

export function saveToCloud(data) {
  return request({
    url: "system/saveToCloud",
    method: "post",
    data,
  });
}

// 获取信息库菜单
export function findMenuTree(){
  return request({
    url : "menu/findMenuTree",
    method : "post",
    headers : {
      "Content-Type" : "application/x-www-form-urlencoded"
    }
  });
}

// 校验选中文档付费情况
export function checkDownload(data){
  return request({
    url : '/esSearch/checkDownload',
    method : 'post',
    data
  })
}
