<template>
  <div class="resultitem-item">
    <p
      class="resultitem-item-title van-multi-ellipsis--l3"
      @click="handleDetail(data)"
      v-html="data.text"
    >
      <!-- 【习近平】习近平总书记在庆祝中国共产党成立100周年大会上的讲话...
        2021-07-05 -->

      <!-- {{data.}} -->
    </p>
    <ul class="resultitem-item-actionbar" >
      <li class="resultitem-item-actionbar-item" @click="handleCheck(data.id)">
        <img src="~@image/select-h.png" alt="" v-if="data.isCheck" />
        <img src="~@image/select-n.png" alt="" v-else />
        标准
      </li>
      <li class="resultitem-item-actionbar-item" @click="handleShuo">
        <img src="~@image/sy-h.png" alt="" v-if="data.isCheckShuo" />
        <img src="~@image/sy.png" alt="" v-else />
        朔源
      </li>
      <li class="resultitem-item-actionbar-item">
        <img src="~@image/cl.png" alt="" />
        {{ data.authority }}
      </li>
      <li class="resultitem-item-actionbar-item">
        <img src="~@image/hot.png" alt="" />
        {{ data.hot }}
      </li>
      
       <li
       @click="insert(data.id)"
        class="resultitem-item-actionbar-item"
      >
        <img src="~@image/insert.png" alt="" />
        插入编辑
      </li>
      <li
        class="resultitem-item-actionbar-item"
        @click="showPopup"
      >
        <img src="~@image/more.png" alt="" />
        更多
      </li>

     
    </ul>

    <van-popup v-model="show" round position="bottom"  :style="{ height: '30%' }">
      <ul class="more">
          <li
          class="more-item"
          @click="handleDownload(data.id, data.title)"
          >
          <img src="~@image/dl.png" alt="" />
          下载
          </li>
        <li
         class="more-item"
          @click="handleShare(data.id, data.title)"
        >
          <img src="~@image/share.png" alt="" />
          分享
        </li>
        <li
         class="more-item"
          @click="handleNetWork(data.id)"
        >
          <img src="~@image/wp.png" alt="" />
          网盘
        </li>

        <li v-if="data.isMark !=true"
         class="more-item" @click="handleCollection(data)"
        >
         <img src="~@image/love.png" alt="" />
          收藏
        </li>
        
        <li v-else
         class="more-item" @click="batchCollect(data)"
        >
         <img src="~@image/love.png" alt="" />
          取消收藏
        </li>

      </ul>
    </van-popup>

  </div>
</template>

<script>
import { saveToCloud,openWordDetail,getUserCollection,batchCollect } from "@api/information";
import qs from "qs";



export default {
  name: "ResultItem",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // isCheck: false,
      // isCheckShuo: false,
      show:false,
      renderHtml:'',   //全文
    };
  },
  computed : {
    //iPhone
    isiPhone(){
      return /iPhone|iPad/i.test(navigator.userAgent);
    }
  },
  methods: {
    //更多
    showPopup(){
       this.show = true
    },
    //插入编辑
    async insert(id){
    
        const requestOptions = {
          type: 1,
          id,
        };
        const result = await openWordDetail(qs.stringify(requestOptions));

        if (result.code === 0) {
          this.renderHtml = result.msg;

          if(window.android){
            const dict = {
                action: 'post',
                data: this.renderHtml
            };
            var jsonstr = JSON.stringify(dict);
            window.android.postMessage(jsonstr);
          }else if(window.webkit){
              const dict = {
                action: 'post',
                data: this.renderHtml
            };
            var jsonstr = JSON.stringify(dict);
            window.webkit.messageHandlers.sendMessageForVue.postMessage(jsonstr)
          }

        } else {
          this.$toast({
            message: result.msg,
          });
        }
    },
    handleDetail(data) {
      // console.log(id);
      // `/user/${userId}`
      let obj = JSON.stringify(data)
      this.$router.push({
        name:"infodetail",
        query:{
          data:obj
        }
      });
    },
    //收藏成功
    async handleCollection(data){
      const info = {
        cid:data.id,
        text:data.text,
        title:data.title
      }
      const result = await getUserCollection(qs.stringify(info))
      if(result.code === 0 ){
        this.$toast("收藏成功！")
        this.show = false
        this.$parent.$options.parent.showData = []
        this.$parent.$options.parent.getData()
      }else if(result.code === 1){
         this.$toast(result.msg)
         this.show = false
      }else{
         this.$toast("网络错误,请联系管理员！")
         this.show = false
      }
    },

    //取消收藏
    async batchCollect(data){
       console.log(data)
       const info = {
         cid:data.id,
       }
       const res = await batchCollect(qs.stringify(info))
       if(res.code === 0){
           this.$toast("取消收藏！")
           this.show = false
           this.$parent.$options.parent.showData = []
           this.$parent.$options.parent.getData()
       }
    },
    handleCheck(id) {
      console.log("选择标准版");
      // this.data.isCheck = !this.data.isCheck;
      this.$emit("handleCheck", id);
    },
    handleShuo() {
      // this.data.isCheckShuo = !this.data.isCheckShuo;
      // console.log("选择朔源版");
    },
    handleDownload(id, title) {
      this.show = false
      this.$emit("handleDownload", { id, title });
      // console.log("handleDownload");
    },
    //  分享单个文件
    handleShare(id, title) {
      if (window.android) {
        this.show = false
        const params = {
          action: "share",
          data: {
            type: 1,
            ids: id,
            filename: title,
          },
        };

        window.android.postMessage(JSON.stringify(params));
      } else if (window.webkit) {
        // ios
          this.show = false
        const params = {
          action: "share",
          data: {
            type: 1,
            ids: id,
            filename: title,
          },
        };
        window.webkit.messageHandlers.sendMessageForVue.postMessage(
          JSON.stringify(params)
        );
      }

      console.log("handleShare");
    },
    async handleNetWork(md5) {
      const requestParam = {
        md5,
      };
      const result = await saveToCloud(qs.stringify(requestParam));
      if (result.code === 0) {
        this.$toast("已转存至云文档");
        this.show = false
      } else {
        this.$toast({
          message: result.msg,
        });
        this.show = false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.resultitem {
  margin-top: 15px;
  padding: 0 15px;
  font-family: PingFang SC;
  &-item {
    padding: 15px 0;
    &-title {
      font-size: 15px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
    }
    &-actionbar {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      overflow: hidden;
      overflow-x: auto;
      &-item {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        font-size: 11px;
        font-weight: 400;
        color: #333333;
        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }
    & + .resultitem-item {
      border-top: 1px solid #e3e3e3;
    }
  }
  .more{
     margin-left: 20px;
     display: flex;
     flex-direction: column;
     height: 100%;
     .more-item{
       padding-top: 24px;
       font-size: 14px;
       font-family: PingFang SC;
       font-weight: 500;
       color: #333333;
       flex: 1;
       display: flex;
      img{
        width: 20px;
        height: 20px;
        margin-right: 15px;
      }
     }
  }
}
</style>
